import { useState, useEffect } from "react";

export function useCounter(intervalMs: number) {
  const [counter, setCounter] = useState(0);
  useEffect(() => {
    const timer = setInterval(() => {
      setCounter((val) => val + 1);
    }, intervalMs);
    return () => clearInterval(timer);
  }, [setCounter, intervalMs]);
  return counter;
}
