import { Outlet } from "react-router-dom";

import styles from "./styles.module.css";

const Layout = () => {
  return (
    <div className={styles.container}>
      {/*<nav>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/blogs">Blogs</Link>
          </li>
          <li>
            <Link to="/contact">Contact</Link>
          </li>
        </ul>
    </nav>*/}
      <Outlet />
    </div>
  );
};

export default Layout;
