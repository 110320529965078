import { observable, runInAction } from "mobx";
import { TTAppAPI, TTAppMatchStatus } from "./TTAppAPI";
import { TTAppTeam } from "./TTAppTeam";
import { TTAppEventStream } from "./TTAppEventStream";
import type { Club, Match } from "./types";
import { TTAppPoule, TTAppPouleTeamStanding } from "./TTAppPoule";

export type TTAppGroupTeamMatch = {
  type: "match";
  status: TTAppMatchStatus;
  match: Match;
};

export type TTAppGroupTeam = {
  team: TTAppTeam;
  matches: TTAppGroupTeamMatch[];
  standing?: TTAppPouleTeamStanding;
};

export type TTAppGroup = {
  name: string;
  teams: TTAppGroupTeam[];
};

export class TTAppClub {
  public readonly api;
  public readonly eventStream: TTAppEventStream;
  public readonly id: string;
  public readonly teams = observable.array<TTAppTeam>([]);
  public readonly poules = observable.array<TTAppPoule>([]);
  private data = observable.box<Club | undefined>(undefined);

  constructor(config: {
    api: TTAppAPI;
    eventStream: TTAppEventStream;
    id: string;
  }) {
    this.api = config.api;
    this.eventStream = config.eventStream;
    this.id = config.id;
  }

  get name() {
    return this.data.get()?.clubname;
  }

  get regionName() {
    return this.data.get()?.regionname;
  }

  getGroups() {
    const groups: TTAppGroup[] = [];
    const clubName = this.name ?? "";
    this.teams.forEach((team) => {
      const name = `${(team.groupName || this.regionName) ?? ""} - ${clubName}`;
      let group = groups.find((group) => group.name === name);
      if (!group) {
        group = {
          name,
          teams: [],
        };
        groups.push(group);
      }
      group.teams.push({
        team,
        matches: team.getCurrentMatches().map((match) => ({
          type: "match",
          status: this.api.getMatchStatus(match),
          match,
        })),
        standing: team.standing,
      });
    });
    return groups;
  }

  async init() {
    const { club, groups } = await this.api.getTeams(this.id);

    // Create poules for all found teams
    // It's possible that multiple teams exist
    // in the same poule, so we only want to create
    // and poll that poule once.
    const poules: TTAppPoule[] = [];
    for (let i = 0; i < groups.length; i++) {
      const { teams } = groups[i];
      for (let j = 0; j < teams.length; j++) {
        const { pouleid, poulename } = teams[j];
        if (!poules.find(({ id }) => pouleid === id)) {
          const poule = new TTAppPoule({
            api: this.api,
            id: pouleid,
            name: poulename,
          });
          poules.push(poule);
        }
      }
    }

    // Create teams for all found groups. It's possible that teams
    // exist multiple times (in different poules), for instance
    // in a Play-off or Play-down poule at the end of the season
    const teams: TTAppTeam[] = [];
    for (let i = 0; i < groups.length; i++) {
      const group = groups[i];
      for (let j = 0; j < group.teams.length; j++) {
        const teamInfo = group.teams[j];
        let team = teams.find(({ id }) => id === teamInfo.teamid);
        if (!team) {
          team = new TTAppTeam({
            api: this.api,
            eventStream: this.eventStream,
            id: teamInfo.teamid,
            name: teamInfo.teamname,
            players: teamInfo.players,
            groupId: group.groupid,
            groupName: group.groupname,
          });
          teams.push(team);
          team.init();
        }
        // Poule should always exist..
        const poule = poules.find(({ id }) => teamInfo.pouleid === id);
        if (poule) team.addPoule(poule);
      }
    }

    // Sort teams 1, 2, 3, ... 9
    teams.sort((a, b) => a.teamNumber - b.teamNumber);
    runInAction(() => {
      this.data.set(club);
      this.teams.replace(teams);
      this.poules.replace(poules);
    });
  }

  fetchMatches() {
    return this.poules.forEach((poule) => poule.fetchMatches());
  }

  fetchStandings() {
    return this.poules.forEach((poule) => poule.fetchStandings());
  }

  async cleanup() {
    this.teams.forEach((team) => team.cleanup());
    runInAction(() => {
      this.data.set(undefined);
      this.teams.replace([]);
    });
  }
}
