import { TTAppMatch } from "../../store";

export function formatTeamName(teamName: string, limit = 12) {
  if (teamName.length < limit) return teamName;
  let words = teamName.split(" ");
  // Filter out things like "(W)", "(S)" and "(H)"
  words = words.filter((word) => !(word.length === 3 && word.startsWith("(")));
  teamName = words.join(" ");
  if (teamName.length <= limit) return teamName;
  // Filter out prefixes with all caps, "TT", "TTV", "GTTC", "TTCV", "ODT", etc..
  if (words[0].length <= 4 && words[0].toUpperCase() === words[0]) {
    words.shift();
  }
  teamName = words.join(" ");
  if (teamName.length <= limit) return teamName;
  // Replace certain words, "en => &", "van => v."
  words = words.map((word) =>
    word === "en" ? "&" : word.toLowerCase() === "van" ? "v." : word
  );
  teamName = words.join(" ");
  return teamName;
}

export function formatScore({ score1, score2 }: TTAppMatch) {
  return score1 === null && score2 === null
    ? "-"
    : `${score1 ?? 0}-${score2 ?? 0}`;
}
