import React from "react";

import { observer, TTAppTeam } from "../../store";
import styles from "./styles.module.css";

type Props = {
  homeTeam: TTAppTeam;
};

export const TTAppNoMatchesItem = observer((props: Props) => {
  const { homeTeam } = props;
  return (
    <div className={styles.matchItemContainer}>
      <div className={styles.matchItemColumn}>
        <div className={styles.matchItemBody}>
          <span className={styles.matchItemHomeTeam}>{homeTeam.name}</span>
        </div>
        <div className={styles.matchItemHeader}>
          <div>
            {!homeTeam.hasBeenFetched
              ? "Bezig met ophalen..."
              : "Geen wedstrijden gevonden"}
          </div>
        </div>
      </div>
    </div>
  );
});
