import { Document, DocumentSource, IDocumentOptions } from "firestorter";

import type { ProjectData } from "./types";
import { TTAppStore } from "./ttapp";
import { autorun } from "mobx";

export class Project extends Document<ProjectData> {
  public readonly ttapp = new TTAppStore();

  constructor(source: DocumentSource, options?: IDocumentOptions) {
    super(source, {
      ...(options ?? {}),
    });

    autorun(() => {
      const { isTTAppEnabled, ttAppClubIds } = this;
      if (isTTAppEnabled && ttAppClubIds.length) {
        this.ttapp.init(ttAppClubIds);
      }
    });
  }

  get isTTAppEnabled() {
    return this.data.ttapp?.enabled === true && this.ttAppClubIds.length;
  }

  get ttAppClubIds() {
    return (
      this.data.ttapp?.clubIds ??
      (this.data.ttapp?.clubId ? [this.data.ttapp?.clubId] : [])
    );
  }

  get message(): string | undefined {
    return this.data.presentation?.message;
  }
}
