import { initializeApp } from "firebase/app";
import { Timestamp, getFirestore } from "firebase/firestore";
import { initFirestorter } from "firestorter";
import makeWebContext from "firestorter/web";

export const firebaseConfig = {
  apiKey: "AIzaSyAAcdV8Mg1mhPYLAUMObYf3XDuXdH3WQHc",
  authDomain: "tafeltennis-tv.firebaseapp.com",
  projectId: "tafeltennis-tv",
  storageBucket: "tafeltennis-tv.appspot.com",
  messagingSenderId: "759714213850",
  appId: "1:759714213850:web:1d1dde5aabbe9513d5dae3",
  measurementId: "G-GHW1FFRN5X",
};

export const firebaseRegion = "europe-west1";

const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);

initFirestorter(makeWebContext({ firestore }));

export { app, Timestamp };
