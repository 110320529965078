import React from "react";
import moment from "moment";

import { observer, TTAppStore } from "../../store";
import { useCounter } from "../../utils";
import styles from "./styles.module.css";
import { TTAppPageProgressBar } from "./TTAppPageProgressBar";

type Props = {
  ttapp: TTAppStore;
  rotateIndex: number;
  rotateInterval: number;
};

export const TTAppHeader = observer((props: Props) => {
  const { rotateIndex, rotateInterval } = props;
  useCounter(30000);
  return (
    <div className={styles.headerContainer}>
      <img
        className={styles.headerLogo}
        src={require("./ttapp.png")}
        alt="TTapp logo"
      />
      <span className={styles.headerTitle}>TTapp</span>
      <span className={styles.headerTime}>{moment().format("LT")}</span>
      <div className={styles.headerProgressBar}>
        <TTAppPageProgressBar
          rotateIndex={rotateIndex}
          rotateInterval={rotateInterval}
        />
      </div>
    </div>
  );
});
