import React from "react";

import { observer } from "../../store";
import styles from "./styles.module.css";

type Props = {
  groupName: string;
  pageIndex: number;
  pageCount: number;
};

export const TTAppGroupHeader = observer((props: Props) => {
  const { groupName, pageIndex, pageCount } = props;
  return (
    <div className={styles.groupHeaderContainer}>
      <div className={styles.groupHeaderText}>{`${groupName}`}</div>
      {pageCount > 1 ? (
        <div className={styles.groupHeaderText}>{`${
          pageIndex + 1
        }/${pageCount}`}</div>
      ) : undefined}
    </div>
  );
});
