import React from "react";
import moment from "moment";

import { observer, TTAppGroupTeamMatch, TTAppTeam } from "../../store";
import styles from "./styles.module.css";
import { formatScore, formatTeamName } from "./utils";

type Props = {
  match: TTAppGroupTeamMatch;
  homeTeam: TTAppTeam;
};

export const TTAppMatchItem = observer((props: Props) => {
  const { homeTeam } = props;
  const { match, status } = props.match;
  return (
    <div className={styles.matchItemContainer}>
      <div className={styles.matchItemColumn}>
        <div className={styles.matchItemHeader}>
          <div>{`${moment(match.playtime).format("ddd D MMM, HH:mm")}`}</div>
          {status === "live" ? (
            <div className={styles.matchItemLive}>Live</div>
          ) : undefined}
        </div>
        <div className={styles.matchItemBody}>
          <span
            className={
              homeTeam.name === match.team1name
                ? styles.matchItemHomeTeam
                : undefined
            }
          >
            {formatTeamName(match.team1name)}
          </span>
          {" - "}
          <span
            className={
              homeTeam.name === match.team2name
                ? styles.matchItemHomeTeam
                : undefined
            }
          >
            {formatTeamName(match.team2name)}
          </span>
        </div>
      </div>
      <div className={styles.matchItemScore}>{formatScore(match)}</div>
    </div>
  );
});
