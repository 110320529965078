// import { functions, firebaseRegion, firebaseConfig } from "./firebase";
import { observable, runInAction } from "mobx";
import "./firebase";
import { Auth } from "./framework";
import { Project } from "./Project";

export class Store {
  public readonly auth = Auth.getInstance();
  private _projectId = observable.box<string>("");

  public readonly project = new Project(() => {
    const projectId = this._projectId.get();
    return projectId ? `projects/${projectId}` : undefined;
  });

  get projectId() {
    return this._projectId.get();
  }
  set projectId(val: string | undefined) {
    runInAction(() => this._projectId.set(val ?? ""));
  }
}
