import React, { Fragment, useEffect, useState } from "react";
import useResizeObserver from "use-resize-observer";

import { observer, TTAppStore } from "../../store";
import styles from "./styles.module.css";
import { TTAppGroupHeader } from "./TTAppGroupHeader";
import { TTAppHeader } from "./TTAppHeader";
import { TTAppTeamItem } from "./TTAppTeamItem";

type Props = {
  ttapp: TTAppStore;
};

const ROTATE_INTERVAL = 12000;

export const TTAppPanel = observer(({ ttapp }: Props) => {
  const { ref, height = 1 } = useResizeObserver<HTMLDivElement>();
  const pages = ttapp.getPages(height);
  const [startTime] = useState(() => Date.now());
  const absoluteRotateStartIndex = Math.floor(startTime / ROTATE_INTERVAL);
  const [absoluteRotateIndex, setAbsoluteRotateIndex] = useState(0);
  const totalRotateCount = pages
    .map((page) => page.rotateCount)
    .reduce((prev, cur) => cur + prev, 0);

  let rotateIndex = absoluteRotateIndex % totalRotateCount;
  const page = pages.find((p) => {
    if (rotateIndex < p.rotateCount) {
      return p;
    } else {
      rotateIndex -= p.rotateCount;
      return undefined;
    }
  });

  useEffect(() => {
    const timer = setInterval(() => {
      setAbsoluteRotateIndex(
        Math.floor(Date.now() / ROTATE_INTERVAL) - absoluteRotateStartIndex
      );
    }, ROTATE_INTERVAL);
    return () => clearInterval(timer);
  }, [setAbsoluteRotateIndex, absoluteRotateStartIndex]);

  return (
    <div className={styles.panelContainer + " " + styles.baseFont}>
      <TTAppHeader
        ttapp={ttapp}
        rotateIndex={absoluteRotateIndex}
        rotateInterval={ROTATE_INTERVAL}
      />
      <div ref={ref} className={styles.panelContent}>
        {page?.groups.map((group) => (
          <Fragment key={group.name}>
            <TTAppGroupHeader
              groupName={group.name}
              pageIndex={group.index}
              pageCount={group.count}
            />
            {group.teams?.map((team) => (
              <TTAppTeamItem
                key={team.team.id}
                team={team}
                columnIndex={rotateIndex}
              />
            ))}
          </Fragment>
        ))}
      </div>
    </div>
  );
});
