import { useEffect } from "react";
import { useCounter } from "./utils";

type Props = {
  reloadInterval?: number;
};

const DEFAULT_RELOAD_INTERVAL = 6 * 60 * 60 * 1000;
const COUNTER_INTERVAL = 60 * 1000;

export function AppReload(props: Props) {
  const { reloadInterval = DEFAULT_RELOAD_INTERVAL } = props;
  const counter = useCounter(COUNTER_INTERVAL);
  useEffect(() => {
    if (counter >= reloadInterval / COUNTER_INTERVAL) {
      window.location.reload();
    }
  }, [counter, reloadInterval]);
  return null;
}
