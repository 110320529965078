import React from "react";

import { observer, TTAppPouleTeamStanding } from "../../store";
import styles from "./styles.module.css";
import { formatTeamName } from "./utils";

type Props = {
  standing: TTAppPouleTeamStanding;
};

export const TTAppStandingItem = observer((props: Props) => {
  const { pouleName, teamname, position, points } = props.standing;
  return (
    <div className={styles.matchItemContainer}>
      <div className={styles.matchItemColumn}>
        <div className={styles.matchItemHeader}>
          <div>{pouleName}</div>
        </div>
        <div className={styles.matchItemBody}>
          <span className={styles.matchItemHomeTeam}>
            {`${formatTeamName(teamname)}`}
          </span>
        </div>
      </div>
      <div className={styles.matchItemRightColumn}>
        <div className={styles.matchItemHeader}>
          <div>{`#${position}`}</div>
        </div>
        <div className={styles.matchItemBody}>
          <span className={styles.matchItemHomeTeam}>{`${points} pt`}</span>
        </div>
      </div>
    </div>
  );
});
