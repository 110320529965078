import { observable, runInAction } from "mobx";
import { TTAppAPI } from "./TTAppAPI";
import { TTAppEventStream } from "./TTAppEventStream";
import { TTAppClub, TTAppGroup, TTAppGroupTeam } from "./TTAppClub";

export type TTAppPageGroup = {
  name: string;
  teams: TTAppGroupTeam[];
  index: number;
  count: number;
};

export type TTAppPage = {
  index: number;
  groups: TTAppPageGroup[];
  rotateCount: number;
};

export class TTAppStore {
  private readonly api = new TTAppAPI();
  public readonly eventStream = new TTAppEventStream();
  _isEnabled = observable.box(false);
  public readonly clubs = observable.array<TTAppClub>([]);

  get isEnabled() {
    return this._isEnabled.get();
  }

  getGroups(): TTAppGroup[] {
    return this.clubs.flatMap((club) => club.getGroups());
  }

  getPages(availableHeight: number): TTAppPage[] {
    const PAGE_HEADER_HEIGHT = 28;
    const PAGE_ITEM_HEIGHT = 43;
    const groups = this.getGroups();
    const pages: TTAppPage[] = [];
    availableHeight = Math.max(
      availableHeight,
      PAGE_HEADER_HEIGHT + PAGE_ITEM_HEIGHT
    );
    let page: TTAppPage | undefined = undefined;
    let pageGroup: TTAppPageGroup | undefined = undefined;
    let pageHeight = 0;

    // Distribute the groups nicely over the pages
    groups.forEach((group) => {
      if (!group.teams.length) return;

      // Start a new page when the current page cannot hold at least 1 header
      // and 1 item of this group
      if (
        !page ||
        pageHeight + PAGE_HEADER_HEIGHT + PAGE_ITEM_HEIGHT > availableHeight
      ) {
        page = { index: pages.length, groups: [], rotateCount: 1 };
        pageHeight = 0;
        pages.push(page);
      }

      // Add the header to the page
      pageGroup = {
        name: group.name,
        teams: [],
        index: 0,
        count: 1,
      };
      page.groups.push(pageGroup);
      pageHeight += PAGE_HEADER_HEIGHT;

      // Add the teams to the page
      group.teams.forEach((team) => {
        // If the page is full, start a new page
        if (pageHeight + PAGE_ITEM_HEIGHT > availableHeight) {
          // TODO: increment count correctly when a group spans
          // more than 2 pages
          pageGroup!.count += 1;
          pageGroup = {
            name: group.name,
            teams: [],
            index: pageGroup!.index + 1,
            count: pageGroup!.count,
          };
          page = { index: pages.length, groups: [pageGroup], rotateCount: 1 };
          pageHeight = PAGE_HEADER_HEIGHT;
          pages.push(page);
        }

        // Add the team to the page
        pageGroup?.teams.push(team);
        pageHeight += PAGE_ITEM_HEIGHT;

        // Update the rotate count
        const teamRotateCount = (team.standing ? 1 : 0) + team.matches.length;
        page!.rotateCount = Math.max(page!.rotateCount, teamRotateCount);
      });
    });
    return pages;
  }

  async init(clubIds: string[]) {
    await this.api.login();

    const clubs: TTAppClub[] = [];
    for (let i = 0; i < clubIds.length; i++) {
      const club = new TTAppClub({
        api: this.api,
        eventStream: this.eventStream,
        id: clubIds[i],
      });
      await club.init();
      clubs.push(club);
    }

    runInAction(() => this.clubs.replace(clubs));

    // Fetch all initial matches
    clubs.forEach((club) => club.fetchMatches());

    // And all initial standings
    clubs.forEach((club) => club.fetchStandings());
  }

  async cleanup() {
    this.clubs.forEach((club) => club.cleanup());
  }
}
