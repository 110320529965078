import { observable, runInAction } from "mobx";
import { getAuth, User, IdTokenResult } from "firebase/auth";

import { app } from "../firebase";

const auth = getAuth(app);

export class Auth {
  private mutableUser = observable.box(auth.currentUser);
  private mutableIsLoading = observable.box(true);
  private readonly displayName = observable.box(
    auth.currentUser?.displayName ?? ""
  );
  private readonly idTokenResult = observable.box<IdTokenResult | undefined>();

  private static instance?: Auth;
  static getInstance(): Auth {
    this.instance = this.instance ?? new Auth();
    return this.instance;
  }

  constructor() {
    auth.onAuthStateChanged((firebaseUser: User | null) => {
      runInAction(() => {
        this.mutableUser.set(firebaseUser);
        this.displayName.set(firebaseUser?.displayName ?? "");
        if (!firebaseUser) this.idTokenResult.set(undefined);
        this.mutableIsLoading.set(false);
      });
    });
  }

  get isLoading() {
    return this.mutableIsLoading.get();
  }

  get user(): User | null {
    return this.mutableUser.get();
  }

  get userId(): string {
    return this.user?.uid ?? "";
  }

  get userName(): string | null {
    if (this.mutableUser.get()) {
      return this.displayName.get() || null;
    } else {
      return null;
    }
  }

  getIdToken() {
    return auth.currentUser?.getIdToken();
  }

  private get tokenResult(): IdTokenResult | undefined {
    if (this.isLoading) {
      return undefined;
    }
    const token = this.idTokenResult.get();
    if (token || !auth.currentUser) {
      return token;
    }

    auth.currentUser.getIdTokenResult().then(
      (token) => runInAction(() => this.idTokenResult.set(token)),
      (error) => {
        console.error(`Failed to obtain user token, error: ${error.message}`);
      }
    );
    return undefined;
  }

  get isAdmin(): boolean {
    return !!this.tokenResult?.claims.admin;
  }

  /* async updateProfile(profile: any) {
    await auth.currentUser?.updateProfile(profile);
    if (profile.displayName) {
      runInAction(() => {
        this.displayName.set(profile.displayName);
      });
    }
  } */
}
