import React from "react";
import { Link } from "react-router-dom";

import styles from "./styles.module.css";

export default function HomePage() {
  return (
    <div className={styles.container}>
      {/* <header className="App-header" />*/}
      <img className={styles.logo} src="/logo192.png" alt="logo" />
      <h1>Tafeltennis TV</h1>
      <p>is still under construction... 🤷 </p>
      <h3>Try these links:</h3>
      <Link to="/project/tafeltennisnijmegen">Tafeltennis Nijmegen</Link>
      <Link to="/project/ttvflash">TTV Flash Eindhoven</Link>
    </div>
  );
}
