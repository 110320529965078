import React, { useEffect } from "react";
import useResizeObserver from "use-resize-observer";

import styles from "./styles.module.css";
import { useParams } from "react-router-dom";
import { observer, useStore } from "../../store";

import { GoogleSlide, TTAppPanel, TTAppFooter } from "../../components";
import { parseAspectRatio } from "../../utils";

const MIN_PANEL_WIDTH = 266;
const MIN_FOOTER_HEIGHT = 48;

export default observer(() => {
  const { projectId } = useParams<{ projectId: string }>();
  const store = useStore();
  const { ref, width = 1, height = 1 } = useResizeObserver<HTMLDivElement>();
  useEffect(() => {
    store.projectId = projectId;
  }, [store, projectId]);

  if (!store.project.hasData) return null;
  const { presentation } = store.project.data;
  const { isTTAppEnabled } = store.project;
  const defaultAspectRatio = isTTAppEnabled ? 4 / 3 : 16 / 9;
  const aspectRatio =
    parseAspectRatio(presentation.aspectRatio) ?? defaultAspectRatio;
  const panelWidth = Math.max(
    MIN_PANEL_WIDTH,
    width - (height - (isTTAppEnabled ? MIN_FOOTER_HEIGHT : 0)) * aspectRatio
  );
  const footerHeight = isTTAppEnabled
    ? Math.max(Math.floor(height - (width - panelWidth) / aspectRatio), 0)
    : 0;

  return (
    <div ref={ref} className={styles.container}>
      <div className={styles.content}>
        <div className={styles.presentation}>
          <GoogleSlide
            url={presentation.url}
            delay={presentation.delay}
            refreshTime={presentation.refreshTime}
          />
        </div>
        <div className={styles.footer} style={{ height: footerHeight }}>
          {isTTAppEnabled ? (
            <TTAppFooter
              ttapp={store.project.ttapp}
              message={store.project.message}
            />
          ) : (
            <div className={styles.footerContent} />
          )}
        </div>
      </div>
      <div
        className={styles.panel}
        style={{ width: isTTAppEnabled ? panelWidth : 0 }}
      >
        <div className={styles.panelContent} style={{ width: panelWidth }}>
          <TTAppPanel ttapp={store.project.ttapp} />
        </div>
      </div>
    </div>
  );
});
