import React from "react";
import Marquee from "react-fast-marquee";

import {
  observer,
  TTAppStore,
  TTAppGroupTeamMatch,
  TTAppMatch,
} from "../../store";
import styles from "./styles.module.css";
import { formatScore } from "./utils";

type Props = {
  ttapp: TTAppStore;
  message?: string;
};

function hasResult(match: TTAppMatch) {
  return match.score1 || match.score2;
}

export const TTAppFooter = observer((props: Props) => {
  const { ttapp, message } = props;
  const groups = ttapp.getGroups();
  const liveMatches: TTAppGroupTeamMatch[] = [];
  groups.forEach((group) => {
    group.teams.forEach((team) => {
      team.matches.forEach((match) => {
        if (match.status === "live") {
          liveMatches.push(match);
        }
      });
    });
  });
  const isLive = liveMatches.length > 0;
  return (
    <div
      className={`${styles.footerContainer} ${styles.baseFont} ${
        isLive ? styles.footerLive : ""
      }`}
    >
      <div className={isLive ? styles.footerText : styles.footerTextSmall}>
        {isLive ? "LIVE" : message ?? "Er zijn momenteel geen live wedstrijden"}
      </div>
      <div className={styles.footerTicker}>
        {isLive ? (
          <Marquee
            key={`liveMatches${liveMatches.length}`}
            speed={100}
            gradientColor={[0xf2, 0x18, 0x18]}
            gradientWidth={80}
          >
            {liveMatches.map(({ match }) => (
              <div key={`match${match.id}`} className={styles.footerTickerItem}>
                {`${match.team1name} - ${match.team2name}`}
                {hasResult(match) ? (
                  <div className={styles.footerTickerScore}>
                    {formatScore(match)}
                  </div>
                ) : undefined}
              </div>
            ))}
          </Marquee>
        ) : undefined}
      </div>
    </div>
  );
});
