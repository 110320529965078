import { observable, runInAction } from "mobx";
import type { IObservableValue } from "mobx";
import { TTAppAPI } from "./TTAppAPI";
import type { Match, Standing, PouleMatches, PouleStandings } from "./types";

export type TTAppPouleTeamStanding = Standing & {
  type: "standing";
  pouleId: number;
  pouleName: string;
  position: number;
};

export class TTAppPoule {
  private readonly api: TTAppAPI;
  readonly id: number;
  readonly name: string;
  private readonly matches: IObservableValue<PouleMatches | undefined>;
  private readonly standings: IObservableValue<PouleStandings | undefined>;
  private lastFetchMatchesTime: number = 0;
  private lastFetchStandingsTime: number = 0;

  constructor(config: { api: TTAppAPI; id: number; name: string }) {
    this.api = config.api;
    this.id = config.id;
    this.name = config.name;
    this.matches = observable.box(undefined);
    this.standings = observable.box(undefined);
  }

  async fetchMatches() {
    // Skip the fetch when it was already fetched (by another team)
    // within the last 5 seconds.
    const now = Date.now();
    if (Math.abs(now - this.lastFetchMatchesTime) < 5000) return;
    this.lastFetchMatchesTime = now;

    // Fetch the matches
    const data = await this.api.getPouleMatches(this.id);
    runInAction(() => {
      this.matches.set(data);
    });
  }

  async fetchStandings() {
    // Skip the fetch when it was already fetched (by another team)
    // within the last 5 seconds.
    const now = Date.now();
    if (Math.abs(now - this.lastFetchStandingsTime) < 5000) return;
    this.lastFetchStandingsTime = now;

    // Fetch the standings
    const data = await this.api.getPouleStandings(this.id);
    runInAction(() => {
      this.standings.set(data);
    });
  }

  get hasBeenFetched() {
    return !!this.matches.get() && !!this.standings.get();
  }

  getMatchesForTeam(teamName: string): Match[] {
    return (this.matches.get()?.matches || []).filter(
      (match) => match.team1name === teamName || match.team2name === teamName
    );
  }

  getStandingForTeam(teamName: string): TTAppPouleTeamStanding | undefined {
    const index = (this.standings.get()?.standings || []).findIndex(
      (standing) => standing.teamname === teamName
    );
    return index >= 0
      ? {
          type: "standing",
          pouleId: this.id,
          pouleName: this.name,
          position: index + 1,
          ...this.standings.get()!.standings[index],
        }
      : undefined;
  }
}
