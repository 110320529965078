import React, { useRef, useEffect, useState } from "react";

import {
  observer,
  TTAppGroupTeam,
  TTAppGroupTeamMatch,
  TTAppPouleTeamStanding,
  TTAppTeam,
} from "../../store";
import { TTAppMatchItem } from "./TTAppMatchItem";
import { TTAppNoMatchesItem } from "./TTAppNoMatchesItem";
import { TTAppStandingItem } from "./TTAppStandingItem";
import styles from "./styles.module.css";

type Props = {
  team: TTAppGroupTeam;
  columnIndex: number;
};

function renderItem(
  item: TTAppGroupTeamMatch | TTAppPouleTeamStanding,
  team: TTAppTeam
) {
  switch (item.type) {
    case "match":
      return <TTAppMatchItem homeTeam={team} match={item} />;
    case "standing":
      return <TTAppStandingItem standing={item} />;
    default:
      return null;
  }
}

export const TTAppTeamItem = observer((props: Props) => {
  const { columnIndex } = props;
  const firstNodeRef = useRef<HTMLDivElement>(null);
  const { team, matches, standing } = props.team;
  const [pollSize, setPollSize] = useState(true);
  const size = firstNodeRef.current?.clientHeight ?? 0;
  const items = standing ? [...matches, standing] : matches;
  const otherItems = size ? items.slice(1) : [];
  const visibleIndex = columnIndex % Math.max(items.length, 1);
  const startOffset = size * -visibleIndex;
  useEffect(() => {
    if (!size) {
      const timer = setTimeout(() => {
        setPollSize(!pollSize);
      }, 1000);
      return () => clearTimeout(timer);
    } else {
    }
  }, [size, pollSize, setPollSize]);
  return (
    <div className={styles.multiMatchContainer}>
      <div
        ref={firstNodeRef}
        className={styles.multiMatchItem}
        style={{ transform: `translateY(${startOffset}px)` }}
      >
        {items.length ? (
          renderItem(items[0], team)
        ) : (
          <TTAppNoMatchesItem homeTeam={team} />
        )}
      </div>
      {otherItems.map((item, index) => (
        <div
          key={`item.${item.type === "standing" ? "standing" : item.match.id}`}
          className={
            styles.multiMatchItem + " " + styles.multiMatchAbsoluteItem
          }
          style={{
            transform: `translateY(${startOffset + (index + 1) * size}px)`,
          }}
        >
          {renderItem(item, team)}
        </div>
      ))}
    </div>
  );
});
