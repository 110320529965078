import { useEffect, useState } from "react";
import useResizeObserver from "use-resize-observer";

import styles from "./styles.module.css";

type Props = {
  rotateIndex: number;
  rotateInterval: number;
};

const WAIT_TIME = 200;

export const TTAppPageProgressBar = (props: Props) => {
  const { rotateIndex, rotateInterval } = props;
  const [prevRotateIndex, setPrevRotateIndex] = useState(-1);
  const { ref, width = 0 } = useResizeObserver<HTMLDivElement>();
  useEffect(() => {
    if (width) {
      const timer = setTimeout(
        () => setPrevRotateIndex(rotateIndex),
        WAIT_TIME
      );
      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rotateIndex, setPrevRotateIndex, !!width]);

  const isStarted = prevRotateIndex === rotateIndex && !!width;
  return (
    <div ref={ref} className={styles.progressBarContainer}>
      {width ? (
        <div
          key={`key${rotateIndex}`}
          className={styles.progressBar}
          style={{
            transform: `translateX(${isStarted ? 0 : -width}px)`,
            transition: `transform ${
              (rotateInterval - WAIT_TIME) / 1000
            }s linear`,
          }}
        />
      ) : undefined}
    </div>
  );
};
