import { TTAppTeam } from "./TTAppTeam";
import type { Match } from "./types";

export type TTAppEvent = {
  type: "matchStarted" | "matchEnded" | "matchUpdated";
  team: TTAppTeam;
  match: Match;
  prevMatch: Match;
  scoredTeamName?: string;
};

export class TTAppEventStream {
  _queue: TTAppEvent[] = [];

  push(event: TTAppEvent) {
    if (this._queue.length > 50) this._queue.splice(0, 1);
    this._queue.push(event);
  }

  pop() {
    if (this.isEmpty) return undefined;
    const event = this._queue[0];
    this._queue.splice(0, 1);
    return event;
  }

  get isEmpty() {
    return !!this._queue.length;
  }
}
