import type { IObservableValue } from "mobx";
import { runInAction } from "mobx";

import {
  enhancedObservable,
  IEnhancedObservableDelegate,
} from "./enhancedObservable";

export class ClockModel implements IEnhancedObservableDelegate {
  private observedRefCount: number = 0;
  public readonly updateInterval: number;
  private intervalId: any;
  private mutableTime: IObservableValue<number>;

  constructor(updateInterval: number = 1000) {
    this.updateInterval = updateInterval;
    this.mutableTime = enhancedObservable(Date.now(), this);
  }

  get time(): number {
    const time = this.mutableTime.get();
    const now = Date.now();
    return Date.now() - time <= this.updateInterval ? time : now;
  }

  get date(): Date {
    return new Date(this.time);
  }

  addObserverRef(): number {
    const res = ++this.observedRefCount;
    if (res === 1) {
      this.intervalId = setInterval(() => {
        runInAction(() => this.mutableTime.set(Date.now()));
      }, this.updateInterval);
    }
    return res;
  }

  releaseObserverRef(): number {
    const res = --this.observedRefCount;
    if (res <= 0 && this.intervalId !== undefined) {
      clearInterval(this.intervalId);
      this.intervalId = undefined;
    }
    return res;
  }
}

export const fastClock = new ClockModel(200);
