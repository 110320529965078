import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./layout/Layout";
import HomePage from "./pages/home/HomePage";
import ProjectPage from "./pages/project/ProjectPage";

export default function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<HomePage />} />
          <Route path="/project/:projectId" element={<ProjectPage />}>
            <Route path="view" element={<ProjectPage />} />
            <Route path="edit" element={<HomePage />} />
            <Route path="*" element={<HomePage />} />
          </Route>
          {/* <Route path="*" element={<NoPage />} /> */}
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
