import { toJS } from "mobx";
import { TTAppAPI } from "./TTAppAPI";
import moment from "moment";
import { TTAppEventStream } from "./TTAppEventStream";
import { TTAppPoule, TTAppPouleTeamStanding } from "./TTAppPoule";
import type { Match, GroupName } from "./types";

export class TTAppTeam {
  private api: TTAppAPI;
  private eventStream: TTAppEventStream;
  readonly id: number;
  readonly name: string;
  readonly players: string[];
  readonly poules: TTAppPoule[];
  readonly groupId: string;
  readonly groupName: GroupName;
  private interval: any;

  constructor(config: {
    api: TTAppAPI;
    eventStream: TTAppEventStream;
    id: number;
    name: string;
    players: string[];
    groupId: string;
    groupName: GroupName;
  }) {
    this.api = config.api;
    this.eventStream = config.eventStream;
    this.id = config.id;
    this.name = config.name;
    this.players = config.players;
    this.groupId = config.groupId;
    this.groupName = config.groupName;
    this.poules = [];
  }

  addPoule(poule: TTAppPoule) {
    this.poules.push(poule);
  }

  init() {
    // Start a periodic timer which checks whether
    // any matches are live and polls them periodically
    // when needed
    let prevLiveMatch = toJS(this.liveMatch);
    this.interval = setInterval(async () => {
      const { liveMatch } = this;
      if (liveMatch) {
        if (!prevLiveMatch) {
          prevLiveMatch = toJS(liveMatch);
          this.eventStream.push({
            team: this,
            type: "matchStarted",
            match: prevLiveMatch,
            prevMatch: prevLiveMatch,
          });
        }

        // TODO: We probably only need to fetch the poule
        // that has the live match here. A team may be
        // part of multiple pouls, e.g. a regular poule and
        // Play-Up/Down poule in which the
        // promovendus/degradant is decided.
        for (let i = 0; i < this.poules.length; i++) {
          await this.poules[i].fetchMatches();
        }

        const newLiveMatch = this.liveMatch || liveMatch;
        if (
          (newLiveMatch.score1 || 0) !== (prevLiveMatch.score1 || 0) ||
          (newLiveMatch.score2 || 0) !== (prevLiveMatch.score2 || 0)
        ) {
          this.onMatchUpdated(prevLiveMatch, toJS(newLiveMatch));
        }
        prevLiveMatch = toJS(newLiveMatch);
      } else if (prevLiveMatch) {
        // TODO: We probably only need to fetch the poule
        // that had the previous live match here.
        for (let i = 0; i < this.poules.length; i++) {
          await this.poules[i].fetchStandings();
        }

        this.eventStream.push({
          team: this,
          type: "matchEnded",
          match: prevLiveMatch,
          prevMatch: prevLiveMatch,
        });
        prevLiveMatch = undefined;
      }
    }, 60000);
  }

  cleanup() {
    clearInterval(this.interval);
    this.interval = undefined;
  }

  get hasBeenFetched() {
    return this.poules
      .map((poule) => poule.hasBeenFetched)
      .reduce((prev, cur) => prev && cur, true);
  }

  get matches(): Match[] {
    return this.poules.flatMap((poule) => poule.getMatchesForTeam(this.name));
  }

  get standing(): TTAppPouleTeamStanding | undefined {
    return this.poules
      .flatMap((poule) => [poule.getStandingForTeam(this.name)])
      .pop();
  }

  getCurrentMatches(mom?: any) {
    mom = mom || moment(this.api.currentDate);

    let futureMatch: Match | undefined;
    let pastMatch: Match | undefined;
    for (let i = 0; i < 16; i++) {
      futureMatch = this.getMatchForWeek(i);
      if (futureMatch) {
        // When a future match was found, search for the last match
        // since that match.
        for (let j = i - 1; j > i - 6; j--) {
          pastMatch = this.getMatchForWeek(j);
          if (pastMatch) break;
        }
        break;
      }
    }

    // When no future matches found, search for the last match played
    if (!futureMatch) {
      for (let i = -1; i > -16; i--) {
        futureMatch = this.getMatchForWeek(i);
        if (futureMatch) break;
      }
    }

    return [
      ...(pastMatch ? [pastMatch] : []),
      ...(futureMatch ? [futureMatch] : []),
    ];
  }

  getMatchForWeek(weekOffset: number) {
    const date = moment(this.api.currentDate).add(weekOffset, "week");
    const { matches } = this;
    const startOfWeek = date.clone().startOf("week");
    const endOfWeek = date.clone().endOf("week");
    return matches.find(
      (match) =>
        startOfWeek.isSameOrBefore(match.playtime) &&
        endOfWeek.isSameOrAfter(match.playtime)
    );
  }

  get liveMatch() {
    return this.matches.find(
      (match) => this.api.getMatchStatus(match) === "live"
    );
  }

  get teamNumber(): number {
    const comps = this.name.split(" ");
    return parseInt(comps[comps.length - 1], 10);
  }

  private onMatchUpdated(prevMatch: Match, match: Match) {
    const team1Scored = (match.score1 || 0) > (prevMatch.score1 || 0);
    this.eventStream.push({
      team: this,
      type: "matchUpdated",
      prevMatch,
      match,
      scoredTeamName: team1Scored ? match.team1name : match.team2name,
    });
  }
}
